html,
body {
  height: 100%;
}

#root {
  height: 100%;
  font-size: 12px;
}

.mapboxgl-ctrl-attrib,
.mapboxgl-ctrl-logo {
  display: none;
  opacity: 0;
}
